@use "../config" as *;
%club-bg {
    content: "";
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;
    position: absolute;
    opacity: 0.05;
    width: 26rem;
    height: 33rem;
    @include bgImg("svg/hexagon.svg");
}
.club {
    &-video,
    &-news,
    &-photo {
        @extend %relative;
        @extend %hidden;
        &:before {
            @extend %club-bg;
        }
    }
    &-video {
        &:after {
            @extend %club-bg;
        }
    }
}
.club {
    &-video,
    &-photo {
        &::before {
            width: 100%;
            height: 100%;
            top: -16rem;
            left: -15rem;
            background-size: cover;
            opacity: .08;
            filter: brightness(0) invert(1);
        }
    }
}
.waf-row-club-news {
    @extend %relative;
    @extend %light-grey-bg;
    @extend %hidden;
    &::before {
        top: -8.2rem;
        left: -9.5rem;
        @extend %club-bg;
    }
}
.waf-row-club-video,
.waf-row-club-photo {
    @extend %relative;
    @extend %hidden;
    &::before {
        top: -3rem;
        left: -9.7rem;
        filter: brightness(0) invert(1);
        transform: rotate(47deg);
        @extend %club-bg;
    }
    .article-list {
        margin-inline: var(--space-2-neg);
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-row-club-news {
        &::before {
            width: 40%;
            height: calc(100% + 10rem);
            right: -22%;
            top: unset;
            left: unset;
        }
    }
    .waf-row-club-video,
    .waf-row-club-photo {
        &::before {
            top: -57%;
            width: 50%;
            height: 150%;
            left: -13%;
            transform: rotate(58deg);
        }
    }
    .club {
        &-video,
        &-photo {
            &::before {
                left: -10rem;
                background-size: contain;
            }
        }
    }
    .waf-row-club-video {
        isolation: isolate;
        &:after {
            content: "";
            width: 30rem;
            height: 30rem;
            right: -9%;
            bottom: -23%;
            z-index: -1;
            filter: brightness(0) invert(1);
            opacity: .2;
            background-repeat: no-repeat;
            background-size: contain;
            pointer-events: none;
            position: absolute;
            @include bgImg("svg/hexagon.svg");
        }
    }
}