@use "../config" as *;
@forward "./common-showcase";
.waf-row-club-showcase {
    @extend %common-article-content;
    @extend %grey-bg;
    .waf-showcase {
        @extend %p-0;
        @extend %m-0;
        > .layout-wrapper {
            @extend %pt-6;
        }
        .img-box {
            height: unset;
            aspect-ratio: 3/4;
        }
        .showcase-wrapper {
            @extend %pb-6;
        }
    }
    .swiper-pagination {
        --swiper-pagination-bullet-inactive-color: var(--white);
        bottom: var(--space-2);
    }
    .dependancy-wrapper {
        backdrop-filter: unset;
        @extend %mx-2-neg;
        .accordion-body {
            padding-bottom: 0;
            @extend %club-secondary-bg;
        }
        .tab-item-matches {
            @extend %club-secondary-bg;
            .card {
                &-list-item {
                    @extend %white-bg;
                    @extend %half-radius;
                }
                &-meta-title {
                    @extend %px-3;
                    @extend %pt-3;
                    @extend %club-secondary;
                }
            }
        }
        .tab-item-matches,
        .tab-item-standing {
            @extend %mx-3-neg;
            @extend %px-3;
            @extend %pt-4;
            @extend %pb-10;
        }
        .tab-item-standing {
            @extend %light-grey-bg;
        }
    }
    .preview-swiper {
        height: unset;
    }
    .article {
        &-title {
            -webkit-line-clamp: 2;
            height: 6rem;
            @extend %mb-4;
        }
        &-content {
            width: 90%;
            left: 0;
            bottom: 3rem;
            right: 0;
            margin: 0 auto;
            border-radius: 0.2rem;
            -webkit-backdrop-filter: blur(2.5rem);
            backdrop-filter: blur(2.5rem);
            box-shadow: 0 0 0.4rem 0.4rem hsl(var(--hsl-black)/0.15);
            @extend %p-4-4;
        }
    }
    .swiper {
        &-button-prev,
        &-button-next {
            left: auto;
            bottom: calc(var(--space-8)*2);
        }
        &-button-prev {
            right: var(--space-12);
        }
        &-button-next {
            right: var(--space-5);
        }
    }
    .tab-item-standing {
        .head-tab {
            bottom: 2.5rem;
        }
    }
}
.dependancy-wrapper {
    @extend %relative;
    @extend %hidden;
}
@media (min-width:$tablet-min-width) {
    .waf-row-club-showcase {
        .article {
            &-content {
                padding: var(--space-6);
                margin: 0;
                max-width: 29rem;
                @include position(null, null, null, var(--container-white-space));
            }
            &-title {
                font-size: 2.8rem;
                -webkit-line-clamp: 5;
                line-height: 3.4rem;
                height: 17rem;
                margin-bottom: var(--space-8);
            }
        }
        .layout-wrapper {
            position: relative;
            overflow: hidden;
        }
        .dependancy-wrapper {
            position: static;
            .accordion {
                &-body {
                    width: 40.5rem;
                    background-color: var(--secondary-dark);
                    height: calc(var(--container-max-width)*var(--21by9-ratio));
                    @include position(3rem, null, null, 100%, absolute);
                    .score-list-section {
                        height: calc(var(--container-max-width)*var(--21by9-ratio) - 15.7rem);
                    }
                    .waf-scorestrip {
                        padding-top: var(--space-0);
                    }
                }
                &-btn {
                    transform: translateY(-50%);
                    @include position(50%, null, null, calc(100% - 3rem), absolute);
                    &[aria-expanded="true"] {
                        left: calc(100% - 43.5rem);
                        + .accordion-body {
                            left: calc(100% - 40.5rem);
                        }
                    }
                }
            }
            .tab-item-matches,
            .tab-item-standing {
                padding-bottom: var(--space-2);
                background-color: transparent;
            }
            .waf-head {
                a {
                    color: var(--white);
                }
            }
        }
        .standings-table {
            overflow-y: auto;
            height: calc(var(--container-max-width)*var(--21by9-ratio) - 15.6rem);
        }
        .waf-showcase {
            .img-box {
                aspect-ratio: 21/9;
            }
            > .layout-wrapper {
                padding-bottom: var(--space-6);
            }
            .showcase-wrapper {
                padding-bottom: var(--space-0);
            }
        }
    }
}
@media (min-width:$tablet-min-width) and (max-width:$desktop-min-width) {
    .waf-row-club-showcase {
        .article {
            &-title {
                font-size: 2rem;
                -webkit-line-clamp: 3;
                line-height: 3rem;
                height: 9rem;
                margin-bottom: var(--space-4);
            }
            &-content {
                padding: var(--space-3);
            }
        }
    }
}
@media (min-width:$large-desktop-min-width) {
    .waf-row-club-showcase {
        .article {
            &-content {
                bottom: 12rem;
                padding-top: var(--space-6);
                left: 6rem;
            }
        }
        .swiper {
            &-button-prev {
                right: unset;
                left: var(--space-12);
            }
            &-button-next {
                right: unset;
                left: calc(var(--space-10) * 2);
            }
        }
    }
}