@use "../config" as *;
.waf-component {
    &.waf-squad {
        @extend %my-0;
        @extend %mx-2-neg;
        @extend %px-3;
        @extend %pt-8;
        @extend %pb-12;
    }
}
.waf-squad {
    @extend %white-bg;
    @extend %more-btn-position;
    .layout-wrapper {
        @extend %relative;
    }
    .filter-section {
        @extend %d-none;
    }
    .squad {
        &-wrapper {
            box-sizing: border-box;
            @include card-count(1.1, var(--space-2));
            @extend %pl-3;
        }
        &-listing {
            @extend %mx-3-neg;
        }
        &-item {
            cursor: pointer;
            &.captain {
                .player-content {
                    background: linear-gradient(84.82deg, var(--club-secondary) -38.35%, var(--club-primary) 64.69%);
                }
                .player-name {
                    .name {
                        @extend %white;
                    }
                    .icon {
                        display: inline-flex;
                        &::after {
                            content: "C";
                        }
                    }
                }
            }
            &:hover {
                .card-action {
                    @include position(0, null, null, 0);
                }
            }
        }
    }
    .player {
        &-thumbnail {
            isolation: isolate;
            flex-direction: row-reverse;
            background: linear-gradient(0.57deg, var(--club-primary) -6.68%, var(--club-varient) 99.86%);
            aspect-ratio: 11/9;
            border-radius: var(--half-radius) var(--half-radius) 0 0;
            background-repeat: no-repeat;
            background-size: cover;
            @extend %flex-sb-n;
            @extend %relative;
            &::after {
                content: "";
                background-size: cover;
                background-repeat: no-repeat;
                opacity: 0.1;
                z-index: var(--z-bg-pattern-neg);
                width: 112%;
                height: 130%;
                filter: invert(1);
                @include bgImg("svg/hexagon.svg");
                @include position(-26%, 2%, null, null);
            }
        }
        &-image {
            z-index: var(--z-negative);
            @extend %w-80;
            @extend %h-100;
            @include position(null, 0.8rem, 0, null);
        }
        &-country {
            @extend %d-none;
        }
        &-number {
            height: max-content;
            opacity: 0.15;
            -webkit-text-stroke: 0.1rem hsl(var(--hsl-white));
            @extend %font-60-pb;
            @extend %mr-3;
            @extend %mt-2;
        }
        &-content {
            border-radius: 0 0 var(--half-radius) var(--half-radius);
            z-index: var(--z-bg-pattern);
            @include border(1, secondary-dark, 3);
            @extend %relative;
            @extend %white-bg;
            @extend %p-3;
            &::after {
                content: "";
                background-size: cover;
                background-repeat: no-repeat;
                z-index: var(--z-bg-pattern-neg);
                mix-blend-mode: overlay;
                background-size: 100% 100%;
                @include bgImg("cssimages/card-dotted-lines.png");
                @include position(0, null, null, 0);
                @extend %w-100;
                @extend %h-100;
            }
        }
        &-stats {
            &-item {
                @include border(1, secondary-medium, 1, bottom);
                @extend %text-center;
                @extend %pb-1;
                @extend %mb-1;
                &:last-of-type {
                    border-bottom: 0;
                    @extend %pb-0;
                    @extend %mb-0;
                }
            }
            &-list {
                width: 27%;
                padding-inline: 3%;
                @extend %hidden;
                @extend %half-radius;
                @extend %white-bg;
                @extend %my-2;
                @extend %ml-2;
                @extend %flex-column-c-n;
                @extend %relative;
                &::after {
                    content: "";
                    width: 9.1rem;
                    height: 10.5rem;
                    background-size: 9.1rem;
                    background-repeat: no-repeat;
                    opacity: 0.1;
                    @include bgImg("svg/hexagon.svg");
                    @include position(null, null, -1rem, -6rem);
                }
            }
            &-title {
                @extend %font-10-pm;
                @extend %uppercase;
                @extend %text-center;
            }
            &-count {
                @extend %font-20-pb;
            }
        }
        &-name {
            height: 6.2rem;
            @extend %hidden;
            @extend %flex-column-c-n;
            .name {
                line-height: 1;
                @extend %uppercase;
                @extend %font-16-pr;
                &.last-name {
                    @extend %font-16-pb;
                }
            }
            .icon {
                justify-content: center;
                align-items: center;
                width: 2rem;
                height: 2rem;
                aspect-ratio: 1/1;
                transform: translateY(-50%);
                @include position(50%, 1.5rem, null, null);
                @extend %circle-radius;
                @extend %white-bg;
                @extend %d-none;
                &::after {
                    content: "";
                    @extend %font-14-pb;
                }
                &::before {
                    content: "";
                    width: calc(100% + 0.6rem);
                    height: calc(100% + 0.6rem);
                    aspect-ratio: 1/1;
                    @include position(-0.3rem, null, null, -0.3rem);
                    @extend %white-bg-2;
                    @extend %circle-radius;
                }
            }
        }
        &-role {
            .role {
                background-color: var(--club-primary);
                display: inline-block;
                @extend %uppercase;
                @extend %white;
                @extend %half-radius;
                @extend %px-2;
                @extend %py-1;
            }
        }
        &-wrap {
            @extend %y-hidden;
            @extend %relative;
            .card-action {
                aspect-ratio: 11/9;
                @include backdropBlur(2.5rem);
                @include position(calc(100% - 8.8rem), null, null, 0);
                @extend %club-primary-bg-2;
                @extend %half-radius;
                @extend %w-100;
                @extend %flex-c-c;
                @extend %transition;
            }
            .btn-site {
                display: inline-block;
                height: max-content;
                @include border(1, white, 10, all);
                @extend %px-3;
                @extend %uppercase;
                @extend %transparent-bg;
                &:hover {
                    @extend %white;
                }
            }
        }
    }
    .swiper {
        &-button {
            @extend %d-none;
        }
    }
    &.waf-squad-page {
        isolation: unset;
        .head-tab {
            @extend %d-none;
        }
        .waf-head .head-wrap > .title {
            width: calc(100% - 11rem);
        }
        .filter-section {
            right: 0;
            @extend %d-block;
            .btn-more {
                background-color: var(--club-primary);
                @extend %white;
            }
        }
        .squad-listing {
            @extend %mx-0;
        }
        .sub-title {
            @extend %font-14-pb;
            @extend %uppercase;
            @extend %mb-3;
        }
        &.waf-squad::after {
            @extend %d-none;
        }
        .player-role {
            @extend %d-none;
        }
        .staff-members {
            pointer-events: none;
        }
    }
}
// .club-1536 {
//     // 2nd squad listing 7th item
//     .squad-listing:nth-child(2) {
//         .squad-item:nth-child(7) {
//             display: none;
//         }
//     }
// }
@include mq(col-md) {
    .waf-squad {
        position: relative;
        overflow: hidden;
        isolation: isolate;
        @include common-more-button();
        &::after {
            content: "";
            width: 30%;
            height: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: var(--z-negative);
            @include position(-15%, 3%);
            @include bgImg("svg/hex-pattern.svg");
        }
        .waf-head {
            a {
                color: var(--white);
            }
        }
        .squad {
            &-wrapper {
                padding-left: 0;
                overflow-x: visible;
                gap: unset;
            }
            &-item {
                width: calc(25% - 1rem);
            }
            &-listing {
                margin-inline: 0;
            }
        }
        .swiper {
            margin-inline: var(--space-3-neg);
            padding-inline: 1.4rem;
            &-button {
                display: flex;
                width: 2.6rem;
                height: max-content;
                @include bg(club-secondary);
                &::after {
                    color: hsl(var(--hsl-white));
                }
                &-prev {
                    left: 0;
                }
                &-next {
                    right: 0;
                }
                &-disabled {
                    opacity: 1;
                    &::after {
                        color: hsl(var(--hsl-white) / 0.6);
                    }
                }
            }
        }
        &.waf-squad-page {
            .squad-wrapper {
                @include card-count(4, 1.5rem, wrap);
            }
            .squad-listing:not(:last-of-type) {
                padding-bottom: 5rem;
                margin-bottom: 5rem;
                @include border(1, secondary-dark, 2, bottom);
            }
            .head-wrap {
                justify-content: space-between;
            }
        }
    }
}
@media screen and (min-width: $desktop-min-width) and (max-width: $large-desktop-min-width) {
    .waf-squad {
        .player {
            &-stats {
                &-count {
                    font-size: 1.4rem;
                }
            }
        }
    }
}
@media screen and (min-width: $mobile-max-width) and (max-width: $desktop-min-width) {
    .waf-squad {
        &.waf-squad-page {
            .squad-wrapper {
                @include card-count(2, 1.5rem, wrap);
            }
        }
    }
}
@media screen and (max-width: $mobile-max-width) {
    .waf-squad {
        &.waf-squad-page {
            .squad {
                &-item {
                    width: 100%;
                    overflow: hidden;
                    background: unset;
                    @include border(1, secondary-dark, 6, bottom);
                    &.captain {
                        padding-top: 0.5rem;
                        .player-wrap {
                            background: linear-gradient(84.82deg, var(--club-primary) -38.35%, var(--club-secondary) 64.69%);
                            &::after {
                                content: "";
                                background-size: cover;
                                background-repeat: no-repeat;
                                z-index: var(--z-squad-pattern);
                                mix-blend-mode: overlay;
                                width: 100%;
                                height: 100%;
                                @include bgImg("cssimages/card-dotted-lines.png");
                                @include position(0, null, null, 0);
                            }
                            .btn-site::after {
                                color: var(--hsl-white);
                            }
                        }
                        .player-content {
                            background: unset;
                        }
                        .player-number {
                            opacity: 1;
                            color: transparent;
                            -webkit-text-stroke: 0.1rem hsl(var(--hsl-white));
                        }
                    }
                    &.support-staff {}
                }
                &-wrapper {
                    padding-left: 0;
                    overflow: visible;
                    gap: var(--space-3);
                    @include flex-config(flex, column, flex-start, flex-start);
                }
                &-listing {
                    margin-bottom: var(--space-8);
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
            .player {
                &-name {
                    display: grid;
                    align-content: center;
                    .name {
                        font-size: 1.2rem;
                        margin-bottom: 0;
                        display: block;
                    }
                    .icon {
                        width: 1rem;
                        height: 1rem;
                        position: static;
                        transform: translateY(0);
                        margin-left: 1rem;
                        &::after {
                            font-size: 0.8rem;
                        }
                    }
                }
                &-number {
                    width: 3rem;
                    font-size: 2.2rem;
                    margin: 0;
                    opacity: 0.6;
                    color: var(--white);
                    -webkit-text-stroke: 0.1rem hsl(var(--hsl-secondary-dark));
                }
                &-image {
                    aspect-ratio: 4/3;
                    width: 6.1rem;
                    height: auto;
                    @include position(null, unset, -1rem, 2rem);
                }
                &-thumbnail {
                    background: unset;
                    aspect-ratio: unset;
                    @include flex-config(flex, row, flex-start, center);
                    &::after {
                        content: unset;
                    }
                }
                &-stats-list {
                    display: none;
                }
                &-wrap {
                    overflow: visible;
                    padding: var(--space-2);
                    border-radius: var(--quarter-radius);
                    @include flex-config(flex, row, flex-start, center);
                    .card-action {
                        height: 100%;
                        background-color: transparent;
                        -webkit-backdrop-filter: unset;
                        backdrop-filter: unset;
                        @include position(0, null, null, 0);
                    }
                    .btn-site {
                        font-size: 0;
                        padding: 0;
                        border: 0;
                        &::after {
                            color: hsl(var(--hsl-secondary-dark));
                            transform: translateY(-50%);
                            @include position(50%, 1.5rem, null, null);
                            @include icon(arrow-right, 14);
                        }
                    }
                }
                &-role {
                    display: none;
                }
                &-content {
                    padding: 0;
                    border: 0;
                    background: unset;
                    margin-left: 6rem;
                    margin-right: 2rem;
                    position: static;
                    &::after {
                        content: unset;
                    }
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-squad {
        &.player-wrap {
            .card-action {
                height: calc((9/12) * 100%);
            }
        }
    }
}