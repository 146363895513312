@use "../config" as *;
@forward "../showcase/common-showcase-dependency";
@forward "./common-club-home-standings";
.waf-standings {
    .waf-body {
        @extend %white-bg;
    }
    .waf-head {
        .title {
            @extend %secondary-dark;
        }
    }
    &.waf-club-standings {
        margin-inline: var(--space-2-neg);
        @extend %px-2;
        @extend %white-bg;
        .head-tab {
            @include position(null, 0, 1.5rem, null);
        }
        .waf-body {
            @extend %transparent-bg;
        }
        .stats {
            &-list {
                flex-wrap: wrap;
                @extend %flex-n-c;
                @extend %gap-2;
            }
            &-item {
                width: calc(50% - var(--space-1));
                @extend %light-grey-bg;
                @extend %quarter-radius;
                @extend %flex-c-c;
                @extend %py-3;
                @each $stats-item,
                $stats-value in $stats-item-list {
                    &.#{$stats-item} {
                        .stats-icon {
                            &::before {
                                @include bgImg("svg/#{$stats-value}.svg");
                            }
                        }
                    }
                }
                &.goal-diff {
                    order: 2;
                    @extend %w-100;
                }
            }
            &-data-item {
                @include flex-config(flex, column-reverse, null, flex-start);
            }
            &-value {
                @extend %font-30-pb;
            }
            &-label {
                opacity: 0.6;
                transition: color 1s;
                @extend %uppercase;
                @extend %font-10-pb;
            }
            &-icon {
                transition: filter 1s;
                &::before {
                    content: "";
                    width: 4.2rem;
                    aspect-ratio: 1/1;
                    background-size: contain;
                    background-repeat: no-repeat;
                    @extend %d-block;
                }
            }
            &-data {
                @extend %flex-n-c;
                @extend %gap-2;
            }
            &-group-item {
                order: 1;
                @extend %w-100;
                @extend %flex-n-c;
                @extend %light-grey-bg;
                .stats-item {
                    width: 33.33%;
                    @extend %relative;
                    @extend %transparent-bg;
                    &:not(:last-of-type)::before {
                        content: "";
                        width: 0.1rem;
                        height: calc(100% - 5rem);
                        z-index: var(--z-bg-pattern);
                        @include position(2.5rem, 0);
                        @extend %secondary-dark-bg-1;
                    }
                }
                .stats-icon {
                    @extend %d-none;
                }
                .stats-data-item {
                    align-items: center;
                }
            }
        }
    }
}
.standings-table {
    @extend %flex;
    .table {
        &-head {
            @extend %secondary-dark-bg;
        }
        &-body {
            .text {
                @extend %secondary-dark;
            }
        }
        &-row-wrap {
            &::after {
                @extend %secondary-light-bg-2;
            }
        }
        &-row {
            &.highlight {
                @extend %relative;
                &::after {
                    content: "";
                    @extend %w-100;
                    @extend %h-100;
                    @extend %position-center;
                }
            }
        }
    }
}
.table-left {
    .table {
        &-body {
            @include bg(shimmer-light, 2);
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-standings {
        &::before {
            content: unset;
        }
        &.waf-club-standings {
            margin-inline: 0;
            padding-inline: 0;
            padding-block: var(--space-12);
            @include common-more-button();
            .layout-wrapper {
                flex-direction: column;
            }
            .waf-head {
                .title {
                    display: block;
                }
                a {
                    color: var(--white);
                }
            }
            .head-tab {
                padding-left: 0;
                width: auto;
                a {
                    background-color: var(--club-primary);
                    &::after {
                        @include flex-config(flex);
                        @include icon(chevron-right, 10);
                    }
                }
            }
        }
    }
    .standings-table {
        .table {
            &-head {
                @include bg(shimmer-light);
                .text {
                    color: var(--secondary-dark);
                }
            }
        }
    }
}
@media screen and (min-width: $tablet-max-width) {
    .waf-standings {
        &.waf-club-standings {
            .stats {
                &-data {
                    position: relative;
                    z-index: var(--z-bg-pattern);
                }
                &-item {
                    width: 16%;
                    flex-shrink: 0;
                    position: relative;
                    cursor: pointer;
                    &::after {
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(285.66deg, var(--club-primary) -63.93%, var(--club-secondary) 80.69%);
                        border-radius: var(--quarter-radius);
                        @include dropup(close);
                        @include position-combo(tl);
                    }
                    &:hover {
                        &::after {
                            @include dropup(open);
                        }
                        .stats-value {
                            color: var(--white);
                        }
                        .stats-label {
                            opacity: 0.6;
                            color: var(--white);
                        }
                        .stats-icon {
                            filter: invert(1) brightness(1) contrast(1) saturate(0);
                        }
                    }
                    &.goal-diff {
                        width: 16%;
                    }
                }
                &-list {
                    flex-wrap: nowrap;
                }
                &-value {
                    font-size: 3.5rem;
                    transition: color 1s;
                }
                &-group-item {
                    position: relative;
                    .stats-item {
                        &::after {
                            right: 0;
                            display: none;
                        }
                        &:first-child::after {
                            display: block;
                        }
                    }
                    &:hover {
                        .stats {
                            &-item {
                                &::before {
                                    background-color: hsl(var(--hsl-white) / 0.2);
                                }
                                &:first-child {
                                    &::after {
                                        left: 0;
                                        @include dropup(open)
                                    }
                                }
                            }
                            &-value {
                                color: var(--white);
                            }
                            &-label {
                                opacity: 0.6;
                                color: var(--white);
                            }
                        }
                        &::before,
                        &::after {
                            pointer-events: none;
                            filter: invert(1) brightness(1) contrast(1) saturate(0);
                        }
                    }
                    .stats-item {
                        &:first-child::after {
                            width: calc(100% * 3);
                        }
                        &:hover {
                            ~ .stats-item {
                                &::after {
                                    content: unset;
                                }
                            }
                        }
                    }
                    &::before,
                    &::after {
                        content: "";
                        width: 24%;
                        height: 100%;
                        background-size: auto 100%;
                        background-repeat: no-repeat;
                        z-index: var(--z-bg-pattern);
                        @include bgImg("svg/field.svg");
                    }
                    &::before {
                        @include position-combo(tl);
                    }
                    &::after {
                        transform: scaleX(-1);
                        @include position-combo(tr);
                    }
                }
            }
        }
    }
}